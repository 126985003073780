.contact-form-section{
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  background: white;
}

.contact-title{
  color: #6d928c;
  text-align:center;
  height: 120px;
  width: 100%;
  display: flex; 
  align-items: center;
  justify-content: center;

  padding: 5.8%;

  font-style: normal;
  font-weight: bold;
  font-size: clamp(15px, 4vw, 25px);
}
/*The entire page container*/
.contact-in {
  width: 100%;
  display: flex;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 30%;

  background-color: white;
  background-attachment: fixed;
  color: #6d928c;
  height: 70vh;
  margin: 0px;
  padding: 0px;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/*The imperial location map*/
.contact-map {
  width: 100%;
  height: 100%;
  flex: 50%;
  justify-content: center;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  border: 1px solid white;
}
iframe {
  width: 100%;
  height: auto;
  border: 0;
}
@media screen and (max-width: 1237px){
  .contact-in{
    flex-direction: column;
    height: 120vh;
    padding-bottom: 5%;
  }
  .contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
/*contact form css*/
.contact-comment{
  width: 100%;
  height: auto;
  flex: 50%;
  display: grid;
  padding-bottom: 5%;
}
.contact-form {
  width: 100%;
  height: auto;
  flex: 50%;
  padding: 30px;
  align-self: center;
  justify-content: center;
}
.contact-comment p {
  margin-bottom: 10px;
  font-size: 17px;
}


.form-txt {
  width: 100%;
  height: 40px;
  flex: 50%;
  color: #000;
  border: 1px solid #6d928c;
  border-radius: 50px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
}
.form-txt::placeholder {
  color: #6d928c;
}
.form-txtarea {
  width: 100%;
  height: 130px;
  color: #000;
  border: 1px solid #6d928c;
  border-radius: 10px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
  font-family: "Poppins", sans-serif;
}
.form-txtarea::placeholder {
  color: #6d928c;
}
.form-btn {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 50px;
  background-color: #6d928c;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
}

.form-title{
  text-align: center;
  font-size: clamp(50%, 9.5vw, 250%);
}
