.navbar {
  background: #6D928C;
  height: 80px;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-size: large;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  justify-content: space-between;
}

.logo-title-group{
  display: flex;
  height:100%;
  margin-left: 15px;
}

.navbar-title-div {
  color: white;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  height:100%;
}
.navbar-title {
  color: white;
  font-size: 15px;
  text-decoration: none;
  align-items: center;
  margin-left: 15px;
}


/*STEM Muslims Logo*/
.logo {
  align-items: center;
  display: flex;
  height: 100%;
  transition-duration: 0.5s;
}
.logo:hover{
  transform: rotate(360deg);
  transition-duration: 1s;
  transform-style: initial;
  cursor: pointer;
}
.logo-img{
  height: 80%;
  max-width: 100%;
  max-height: 100%;
  display: block;
}


.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: center;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #e5e8e7;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;

  height: 100%;
}

.nav-links.active {
  border-bottom: 4px solid #e5e8e7;
  font-weight: bold;
}

.nav-links:hover {
  border-bottom: 4px solid #e5e8e7;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #e5e8e7;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.navbar-items{
  font-size: medium;
  display: flex;
  justify-content: flex-end;
}

/*Responsive to different screens*/
@media screen and (max-width:980px) and (min-width: 960px) {
  .img-responsive {
    margin-left:10px;
  }
}
@media screen and (max-width: 980px) {
  .NavbarItems {
    position: relative;
  }
  .navbar-title {
    /* font-size:25px;
    margin-left: 5px; */
    font-size: 12px;
    justify-content: center;
    display: flex;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .img-responsive {
    position :absolute;
    top: 0;
    left:0;
    height: 100px;
    margin-left:0px;
  }
  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

@media screen and (max-width: 340px) {
  .navbar-title{
    font-size: 10px;
  }
}