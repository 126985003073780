/* Committee heading*/
.CommitteePage body {
  font-family: "Poppins", sans-serif;
}

.CommitteePage .header {
  background-color: #e5e8e7;
  text-align: center;
  height: 145px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(25px, 4vw, 38px);
}

.CommitteePage .divider {
  width: 90%;
}

/*Head brother and sister*/

.CommitteePage .committee-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: baseline;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 30px;
  margin-right: 30px;
}

.CommitteePage .head-name {
  background: #6d928c;
  color: white;
  font-weight: 900;
  width: auto;
  padding: 7px 15px;
  border-radius: 15px;
  margin: 15px;
  font-weight: normal;
  font-style: normal;
}

.CommitteePage b {
  color: #6d928c;
}

.CommitteePage .head-title {
  color: black;
  font-size: clamp(20px, 3vw, 28px);
  font-weight: bold;
  font-style: italic;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 5%;
}

/*Committee members*/
.CommitteePage hr {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.CommitteePage .committee-members {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 50px;
  column-gap: 5px;
  align-items: baseline;
  justify-content: center;
  justify-items: center;
  margin-top: 30px;
  margin-bottom: 12%;
}

@media screen and (max-width: 960px) {
  .CommitteePage .committee-members {
    grid-template-columns: auto;
    row-gap: 70px;
  }
  .CommitteePage .committee-head {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  .CommitteePage .head-member {
    margin-bottom: 12%;
  }
}

.CommitteePage .member-name,
.CommitteePage .member-role {
  color: black;
  text-align: center;
  font-size: clamp(15px, 4vw, 25px);
}
.CommitteePage .member-name {
  font-weight: bold;
  margin: 0;
}
.CommitteePage .member-role {
  font-style: italic;
  margin: 0;
}

.CommitteePage .head-member {
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 8%;
  padding: 5px;
  width: 350px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.CommitteePage .member {
  text-align: center;
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  border-radius: 8%;
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  padding: 5px;
  width: 350px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.CommitteePage .head-member:hover {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.CommitteePage .member:hover {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.CommitteePage .column {
  align-self: center;
  /*border: 1px solid rgba(0, 0, 0, 0.8);*/
}

.CommitteePage .icon {
  background-color: #6d928c;
  color: white;
  padding: 10px;
  font-size: 15px;
  border-radius: 50%;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  margin: 10px;
}

.CommitteePage .icon:hover {
  background-color: rgba(200, 200, 200, 0.6);
  color: black;
  cursor: pointer;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.CommitteePage .icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AcademicRepsPage .info-div {
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center; 
  height: 100%; 
  padding: 10px;
}
