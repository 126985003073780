/* Introduction component */
.intro {
  /* --- Background Image Formating --- */
  /* Full height */
  background-image: url("../../public/welcomeimg.png");
  background-attachment: fixed;
  color: white;
  height: 88vh;
  margin: 0px;
  padding: 0px;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Welcome greeting formating */
  width: 100%;
  height: 92.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.greetings {
  /* Salaam formating */
  font-size: clamp(85%, 3vw, 150%);
  font-style: italic;
  text-align: center;
  margin: 0;
}
/* Greeting formating*/
.welcome {
  font-size: clamp(85%, 8vw, 400%);
  text-align: center;
  margin: 0;
}

/* ----- Who We Are component ----- */
/* aligns all information sections to one row*/
.info-sect {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-wrap: wrap;
  flex: 1 2 auto;
  overflow-y: auto;
}

.info-container {
  color: #6d928c;
  margin: 0 5rem;
  text-align: center;
  width: 33%; 
  height: min-content;
  
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2% 0 2% 0;
}

.info-title {
  color: #6d928c;
  text-align: center;
  font-size: clamp(2rem, 2.5vw, 10rem);

  font-weight: bold;
  margin-bottom: 3rem;
}

.info-desc {
  font-size: clamp(0.6rem, 1.2vw, 8rem);
  
  color: #6d928c;
  text-align: center;
  margin: 0 10% 5% 10%;
  width: 80%;

  flex-shrink: 1;
}


/* -- Specific information sections --*/
.our-mission-title,
.our-mission-desc,
.our-mission-info {
  color: #6d928c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.who-we-are-info,
.our-mission-info,
.our-values-info {
  height: 53vh;
}

/* makes all information section one colummn*/
@media screen and (max-width: 1237px) {
  .info-sect {
    display: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    flex: auto;
  }
  .our-mission-title,
  .our-mission-desc,
  .our-mission-info {
    background-image: url("../../public/aid.jpg");
    background-attachment: fixed;
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .info-desc {
    font-size: clamp(30%, 5vw, 150%);
    text-align: center;
    margin: 0 10% 5% 10%;
  }
  .info-container {
    width: 100%;
    margin: 0;
  }
}

/* -- sponsor logos -- */
.img-logo-inverted {
  filter: invert(1);

  width: 50px;
  height: 50px;
}
.sponsor-container {
  width: 100%;
  height: 50vh;

  /*display: grid;*/
  display: none;
  /* change above css to include or exclude sponsor section <-<-<-<-<------------------*/

  align-items: center;
  justify-content: center;
}
.sponsor-title {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 3rem;
  align-self: flex-end;
}
.sponsor-in ul {
  padding: 0;
  margin: 0;
  margin-top: 10%;
  align-items: center;
}
.sponsor-in ul li {
  list-style: none;
  display: inline-block;
  width: 150px;
  height: 100px;
}
.sponsor-in ul li img {
  width: 60%;
  height: 90%;
}
.sponsor-container {
  /* --- Background Image Formating --- */
  /* Full height */
  background-image: url("../../public/welcomeimg.png");
  background-attachment: fixed;
  color: white;

  margin: 0px;
  padding: 0px;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
