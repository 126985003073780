.EventsPage .header {
  background-color: #e5e8e7;
  text-align: center;
  height: 145px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(25px, 4vw, 38px);
}

.EventsPage .launchEvent {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.EventsPage .launchText {
  margin: 30px;
  width: 60%;
  height: auto;
}

.EventsPage .title {
  text-align: center;
  font-family: "Poppins";
  color: #85a8a4;
}

.EventsPage .launchDescContainer {
  background: #f4faf6;
}

.EventsPage .launchDesc {
  padding: 20px;
}

.EventsPage .imgContainer {
}

.EventsPage .launchImg {
  max-width: 350px;
  max-height: 350px;
}

.EventsPage .divider {
  position: relative;
  width: 748px;
  height: 0px;
  margin: auto;
  border: 1px solid #6d928c;
  background-color: #6d928c;
  transform: rotate(0deg);
  border-radius: 4px;
}

.EventsPage .eventTypes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.EventsPage .eventType {
  /* width: 40%; */
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
}

.EventsPage .eventTypeImg {
  /* width: 100%; */
  /* height: 100%; */
  width: 558px;
  /* min-width: 558px; */
  height: 430px;
  /* min-height: 430px; */
}
