/* Alumni contacts heading */

.AlumniContactsPage body {
  font-family: "Poppins", "sans-serif";
}

.AlumniContactsPage .header{
  background-color: #e5e8e7;
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
}

.AlumniContactsPage .header-title{
  color:black;
  text-align: center;
  align-items: center;
  font-weight: bold;
  width: 80%;
  font-size: clamp(24px, 4vw, 26px);
}

.AlumniContactsPage .header-description{
  text-align: center;
  width: 85%;
  justify-content: center;
  font-weight: normal;
  margin-bottom: 2%;
  font-size: clamp(10px, 4vw, 14px);
}

/* Alumni contacts */


.AlumniContactsPage .alumni-contacts{
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); */
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

@media (max-width: 1200px) {
  .AlumniContactsPage .alumni-contacts{
    grid-template-columns: 1fr;
  }
}

.AlumniContactsPage .alumni-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 50px;
}


.AlumniContactsPage .alumni-card-logo-container {
  min-width: 100%;
  position: absolute;
  bottom: 212px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AlumniContactsPage .alumni-card-logo {
  background-image: url("../../public/imperial-logo.png");
  min-width: 100px;
  min-height: 100px;
  border-radius: 400px;
  position: relative;
}

.AlumniContactsPage .alumni-card-info-container {
  padding-left: 40px;
  padding-right: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #b3cad3;
  border-radius: 20px;
}

.AlumniContactsPage .titles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AlumniContactsPage .bio-and-grad-container {
  display: flex;
  flex-direction: row;

}

.AlumniContactsPage .bio {
  width: 50%;
}

.AlumniContactsPage .graduation {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
