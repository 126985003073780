/* Committee heading*/
.AcademicRepsPage body {
  font-family: "Poppins", sans-serif;
}

.AcademicRepsPage .header {
  background-color: #e5e8e7;
  text-align: center;
  height: 145px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  font-style: normal;
  font-weight: bold;
  font-size: clamp(25px, 4vw, 38px);
}

.AcademicRepsPage .divider {
  width: 90%;
}

.AcademicRepsPage b {
  color: #6d928c;
}

/*Committee members*/
.AcademicRepsPage hr {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.AcademicRepsPage .committee-members {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  row-gap: 50px;
  column-gap: 5px;
  align-items: center;
  align-items: baseline;
  justify-content: space-evenly;
  justify-items: center;
  margin-top: 30px;
  margin-bottom: 12%;
  margin-left: 30px;
  margin-right: 30px;
}

@media screen and (max-width: 960px) {
  .AcademicRepsPage .committee-members {
    grid-template-columns: auto;
    row-gap: 70px;
  }
}

.AcademicRepsPage .member-name,
.AcademicRepsPage .member-role {
  color: black;
  text-align: center;
  font-size: clamp(15px, 4vw, 25px);
}
.AcademicRepsPage .member-name {
  font-weight: bold;
  margin: 0;
}
.AcademicRepsPage .member-role {
  font-style: italic;
  margin: 0;
}

.AcademicRepsPage .member {
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  text-align: center;
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 8%;
  padding: 5px;
  width: 350px;
  height: 100%;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.AcademicRepsPage .head-member:hover {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  
}

.AcademicRepsPage .member:hover {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.AcademicRepsPage .column {
  align-self: center;
  /*border: 1px solid rgba(0, 0, 0, 0.8);*/
}

.AcademicRepsPage .icon {
  background-color: #6d928c;
  color: white;
  padding: 10px;
  font-size: 15px;
  border-radius: 50%;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  margin: 10px;
}

.AcademicRepsPage .icon:hover {
  background-color: rgba(200, 200, 200, 0.6);
  color: black;
  cursor: pointer;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.AcademicRepsPage .icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AcademicRepsPage .info-div {
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center; 
  height: 100%; 
  padding: 10px;
}
