@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
}
*{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
.container{
	max-width: 1170px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
    justify-content:space-between;
}
ul{
	list-style: none;    
}
a{
    color: white;
}
.footer{
	background-color: #6D928C;
    padding: 70px 0;
}
.footer-col{
   width: 33%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: clamp(90%, 4vw, 130%);
	color: #ffffff;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
/* Underline */
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #ffffff;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li a{
	font-size: clamp(80%, 4vw, 100%);
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #ffffff;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(200, 200, 200, 0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: rgba(200, 200, 200, 0.6);
}
.footer-email{
    width: 50%;
    height: 35px;
    border-radius: 15px;
    outline: none;
    border: none;
    padding-left: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.footer-email::placeholder{
    color: #6D928C;
}

input, select, textarea{
    color: black;
}

.footer-email-btn{
    width: 100px;
    height: 40px;
    border-radius: 15px;
    background-color: rgba(200, 200, 200, 0.2);
    border: none;
    outline: none;
    font-size: 1rem;
	color: white;
}
.footer-email-btn:hover{
	background-color: rgba(200, 200, 200, 0.6);
    transition: all 0.5s ease;
}
.copyright{
	text-align: center;
	align-self: center;
	color: white;
	padding-top: 4%;
	width: 100%;
	font-weight: bold;
	font-size: clamp(80%, 4vw, 100%);
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}